/* Skills.css */

.skills-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #222222;
    color: white;
    min-height: 100vh;
  }
  
  .logo-source {
    margin-bottom: 20px;
  }
  
  .button-group {
    margin-bottom: 20px;
    display: flex;
    gap: 10px;
  }
  
  .grid-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(50px, 1fr)); /* Adjust size as needed */
    gap: 50px;
    width: 100%;
    max-width: 600px; /* Adjust max-width as needed */
    margin: auto;
  }
  
  .ball-container {
    width: 100px;  /* Adjust size as needed */
    height: 100px; /* Adjust size as needed */
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .ball-container canvas {
    width: 100%;
    height: 100%;
  }
  