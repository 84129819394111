/* HomePage.css */
.HomePage {
    text-align: center;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
}

.center-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
}

.logo-source {
    display: flex;
    gap: 10px;
    width: 100px;
    height: 100px;
    position: absolute;
    top: 30px;
    left: 20px;
    z-index: 2;
    transition: transform 0.3s ease, opacity 0.3s ease;
}

.logo-source:hover {
    transform: scale(1.1); /* Example effect: scales the image up slightly */
    opacity: 0.8; /* Example effect: reduces opacity */
}

h1 {
    position: relative;
    font-size: 7.5em;
    z-index: 2;
    color: #f58f7c;
    font-family: Helvetica, Sans-Serif;
    font-weight: bold;
    margin-top: 0;
    margin-bottom: -10px;
}

h3 {
    position: relative;
    font-size: 3.5em;
    padding: 0;
    margin: 0;
    z-index: 2;
    font-family: 'HelveticaNeue-Light', 'Helvetica Neue Light', 'Helvetica Neue', Helvetica, Arial, 'Lucida Grande', sans-serif;
    font-style: italic; 
    color: #FFFFFF;
    line-height: 1;
}

.typewriter-text {
    position: relative;
    font-size: 3.5em;
    padding: 0;
    margin: 0;
    z-index: 2;
    font-family: 'HelveticaNeue-Light', 'Helvetica Neue Light', 'Helvetica Neue', Helvetica, Arial, 'Lucida Grande', sans-serif;
    font-style: italic; 
    color: #FFFFFF;
    line-height: 1;
}

.button-group {
    display: flex;
    gap: 10px;
    position: absolute;
    top: 30px;
    right: 20px;
    z-index: 2;
}

.particles {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 1; /* Ensure particles are in the background */
}
